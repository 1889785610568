import React from 'react'
import { BrowserRouter } from 'react-router-dom'

import Frames from './Frames'
import Birds from './components/UI/Birds/Birds'
import LangProvider from './components/Context/LangContext'

/* MOMENT */
import moment from 'moment-timezone'
moment.tz.setDefault('UTC')

function App() {
  return (
    <BrowserRouter>
      <LangProvider>
        <Frames />
        <Birds />
      </LangProvider>
    </BrowserRouter>
  )
}

export default App
