import React, { createContext } from 'react'
import { languageConstants, uiConstants } from '../../constants'
import useLocalStorage from 'use-local-storage'

export const LangContext = createContext(null)

const LangProvider = ({ children }) => {
  const userLang = (navigator.language || navigator.userLanguage).split('-')[0]

  const [language, setLanguage] = useLocalStorage(
    uiConstants.localStorage.language,
    languageConstants.locals[userLang] ? userLang : languageConstants.locals.en
  )

  const switchLanguage = (l) => {
    setLanguage(l)
  }

  return (
    <LangContext.Provider
      value={{
        switchLanguage,
        language,
        stories: languageConstants.stories[language]
      }}
    >
      {children}
    </LangContext.Provider>
  )
}

export default LangProvider
