import React, { useMemo } from 'react'
import { Swirl } from 'react-ambient-canvas-backgrounds'

import css from './Birds.module.scss'

// https://github.com/sidahmedabdelillah/react-ambient-canvas-backgrounds

const Birds = () => (
  <div className={css.Birds}>
    {useMemo(
      () => (
        <Swirl
          particleCount={700}
          // rangeY={200}
          // rangeX={1200}
          baseTTL={150}
          // baseSpeed={0.1}
          // rangeSpeed={2}
          baseRadius={1}
          rangeRadius={10}
          baseHue={220}
          rangeHue={100}
          // noiseSteps={8}
          // xOff={0.00125}
          // yOff={0.00125}
          // zOff={0.00125}
        />
      ),
      []
    )}
  </div>
)

export default Birds
