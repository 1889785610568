import Onfield from '../assets/onfield.svg'
import Online from '../assets/online.svg'
import PlayGamez from '../assets/playgamez.svg'
import Star from '../assets/star.svg'

export const uiConstants = {
  social: [
    {
      name: 'instagram',
      icon: 'fa-brands fa-instagram',
      link: 'https://www.instagram.com/johannes.pics/'
    },
    {
      name: 'facebook',
      icon: 'fa-brands fa-facebook',
      link: 'https://www.facebook.com/johannes.exp'
    },
    {
      name: 'tiktok',
      icon: 'fa-brands fa-tiktok',
      link: 'https://www.tiktok.com/@johannes.pics'
    },
    {
      name: 'linkedin',
      icon: 'fa-brands fa-linkedin',
      link: 'https://www.linkedin.com/company/johannes-exp'
    },
    {
      name: 'youtube',
      icon: 'fa-brands fa-youtube',
      link: 'https://www.youtube.com/@johannes-pics'
    },
    {
      name: 'phone',
      icon: 'fa-solid fa-phone',
      link: 'tel:+390249365029'
    },
    {
      name: 'email',
      icon: 'fa-regular fa-envelope',
      link: 'mailto:info@johannes.pics'
    }
  ],
  menu: [
    {
      label: 'home',
      link: '/'
    },
    {
      label: 'whoWeAre',
      link: '/who-we-are'
    },
    {
      label: 'whatWeDo',
      link: '/what-we-do'
    },
    {
      label: 'caseHistory',
      link: '/case-history'
    },
    {
      label: 'contacts',
      link: '/contacts'
    }
  ],
  contacts: [
    {
      name: 'name',
      required: true,
      type: 'text'
    },
    {
      name: 'email',
      required: true,
      type: 'email'
    },
    {
      name: 'phone',
      required: false,
      type: 'tel'
    },
    {
      name: 'message',
      required: true,
      type: 'textarea'
    }
  ],
  whatWeDo: [
    {
      image: Onfield,
      title: 'Live Experiences',
      key: 'onfield'
    },
    {
      image: Online,
      title: 'Digital Experiences',
      key: 'online'
    },
    {
      image: Star,
      title: 'Stunning augmented reality',
      key: 'star'
    },
    {
      image: PlayGamez,
      title: 'Branded gaming solutions',
      key: 'playgamez'
    }
  ],
  emailjs: {
    serviceId: 'service_ke2ahzm',
    templateId: 'template_c2vzppw',
    publicKey: 'P1q0MJKDEqb-IAikx'
  },
  caseHistory: [
    {
      brand: 'Armani',
      video: 'afk5MZxIFsk'
    },
    {
      brand: 'Montblanc',
      video: 'hkHrwVprbkw'
    },
    {
      brand: 'Volkswagen',
      video: 'Xfqa1p9dbEQ'
    },
    {
      brand: 'Adidas',
      video: 'RTEUsf-I2Ns'
    },
    {
      brand: 'Chanel',
      video: 'MzX7_OWoUUg'
    },
    {
      brand: 'Max Mara',
      video: 'Uxzj3kLZYo0'
    },
    {
      brand: 'Guess',
      video: 'bJI3E2dY1Rk'
    },
    {
      brand: 'Calzedonia',
      video: 'SqBbeX2Unb8'
    },
    {
      brand: 'Chanel',
      video: 'voxddhcq9og'
    },
    {
      brand: 'Reebok',
      video: 'kPMaH8U2azE'
    },
    {
      brand: 'Esselunga',
      video: 'sC9tbDfZtxQ'
    },
    {
      brand: 'Bulgari',
      video: '6-yLhcMzNJM'
    },
    {
      brand: 'Saint Laurent',
      video: 'zWS4LBd55MQ'
    }
  ],
  localStorage: {
    language: 'language'
  }
}
