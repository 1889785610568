export const languageConstants = {
  locals: {
    it: 'it',
    en: 'en'
  },
  stories: {
    it: {
      home: 'Home',
      whoWeAre: 'Chi siamo',
      whatWeDo: 'Cosa facciamo',
      caseHistory: 'Case history',
      contacts: 'Contatti',
      typewriter: [
        'Creiamo tecnologie esperienziali,\nfisiche e digitali,\nin grado di connettere persone e brand.',
        'Siamo artigiani digitali:\nle nostre soluzioni, sia offline che online,\nsono tutte costruite e sviluppate in casa, su misura.',
        'Siamo il partner giusto\nper i brand che vogliono essere ricordati\ne desiderano qualcosa di unico e interamente personalizzato.'
      ],
      findOutMore: 'Scopri di più',
      onfield:
        "Sviluppiamo soluzioni tecnologiche custom-made per eventi onfield con l'obiettivo di generare ingaggio per gli ospiti presenti un alto livello di ingaggio e visibilità social e lead per i brand. Non solo per il fisico, ma anche per il phygital, grazie ad attivazioni sviluppate ad hoc, come qr code.",
      online:
        'Sviluppiamo servizi digitali personalizzabili, come piattaforme online per eventi virtuali o in 3d per la ricreazione di ambienti totalmente navigabili, e tool di ingaggio esperienziali, quali digital photo&video booth, quiz & survey, gaming.',
      star: 'La nostra unit dedicata alla realtà aumentata, attraverso lo sviluppo di branded effect (filtri) per i social network e attivazioni con contenuti interattivi in ar su web app o app per ios e android, come packaging preview, target object, fashion show, furniture placement e molto altro.',
      playgamez:
        'Sviluppiamo e disegniamo soluzioni gaming personalizzate, tra cui advergame, videogiochi totalmente brandizzabili. Le nostre soluzioni sono in grado di funzionare su tutti i dispositivi e di essere fruibili attraverso web app o app native scaricabili su ios e android.',
      name: 'Nome Cognome',
      email: 'Email',
      phone: 'Telefono',
      message: 'Messaggio',
      send: 'Invia messaggio',
      sending: 'Invio in corso',
      send_success:
        'Messaggio inviato con successo, ti contatteremo il prima possibile.',
      send_error: "Errore nell'invio del messaggio",
      accept_privacy:
        'Inviando questo messaggio accetti la nostra Privacy Policy',
      who_we_are: [
        "Johannes è un'experiential company fondata nel 2015 a milano.",
        'Siamo creatori di esperienze vere e proprie, che siano fisiche o digitali.',
        'Siamo un gruppo di creativi, sviluppatori, project manager, gamers, videomaker.'
      ]
    },
    en: {
      home: 'Home',
      whoWeAre: 'Who we are',
      whatWeDo: 'What we do',
      caseHistory: 'Case history',
      contacts: 'Contacts',
      typewriter: [
        'We create experiential technologies,\nphysical and digital,\ncapable of connecting people and brands.',
        'We are digital artisans:\nour solutions, both offline and online,\nare all built and developed in-house, tailored to measure.',
        'We are the right partner\nfor brands that want to be remembered\nand desire something unique and entirely personalized.'
      ],
      findOutMore: 'Find out more',
      onfield:
        'We develop custom-made technological solutions for onfield events with the aim of generating engagement for the guests present a high level of engagement and social visibility and leads for the brands. Not only for the physical, but also for the phygital, thanks to ad hoc activations, such as qr code.',
      online:
        'We develop customizable digital services, such as online platforms for virtual events or in 3d for the recreation of totally navigable environments, and experiential engagement tools, such as digital photo&video booth, quiz & survey, gaming.',
      star: 'Our unit dedicated to augmented reality, through the development of branded effect (filters) for social networks and activations with interactive content in ar on web app or app for ios and android, such as packaging preview, target object, fashion show, furniture placement and much more.',
      playgamez:
        'We develop and design customized gaming solutions, including advergame, video games that are totally brandable. Our solutions are able to work on all devices and be usable through web app or native apps downloadable on ios and android.',
      name: 'Full Name',
      email: 'Email',
      phone: 'Phone',
      message: 'Message',
      send: 'Send message',
      sending: 'Sending message',
      send_success:
        'Message sent successfully, we will contact you as soon as possible.',
      send_error: 'Error sending message',
      accept_privacy: 'By sending this message you accept our Privacy Policy',
      who_we_are: [
        'Johannes is an experiential company founded in 2015 in milan.',
        'We are creators of real experiences, whether physical or digital.',
        'We are a group of creatives, developers, project managers, gamers, videomakers.'
      ]
    }
  }
}
