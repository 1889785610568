import React, { lazy, Suspense } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'

import PageLoader from './components/UI/PageLoader/PageLoader'

const LayoutLazy = lazy(() => import('./components/Containers/Layout/Layout'))
const ContentLayoutLazy = lazy(() =>
  import('./components/Containers/ContentLayout/ContentLayout')
)

const HomeLazy = lazy(() => import('./components/Pages/Home/Home'))
const WhoWeAreLazy = lazy(() => import('./components/Pages/WhoWeAre/WhoWeAre'))
const WhatWeDoLazy = lazy(() => import('./components/Pages/WhatWeDo/WhatWeDo'))
const CaseHistoryLazy = lazy(() =>
  import('./components/Pages/CaseHistory/CaseHistory')
)
const ContactsLazy = lazy(() => import('./components/Pages/Contacts/Contacts'))

const Frames = () => {
  const location = useLocation()

  return (
    <Suspense fallback={<PageLoader />}>
      <AnimatePresence>
        <Routes location={location} key={location.pathname}>
          <Route element={<LayoutLazy />}>
            <Route index element={<HomeLazy />} />
            <Route path='who-we-are' element={<WhoWeAreLazy />} />
            <Route element={<ContentLayoutLazy />}>
              <Route path='what-we-do' element={<WhatWeDoLazy />} />
              <Route path='case-history' element={<CaseHistoryLazy />} />
              <Route path='contacts' element={<ContactsLazy />} />
            </Route>
          </Route>
        </Routes>
      </AnimatePresence>
    </Suspense>
  )
}

export default Frames
